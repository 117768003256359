import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography, Container, Grid } from '@mui/material'
import Navbar from '../shared/Navbar'
import { luckyDrawBanner, tngBanner } from '../constant/image'

const Promotions = () => {
  return (
    <Container component='main'>
      <Navbar />
      <Box>
        <Box display='flex' justifyContent='center' textAlign='center' mt={15} mb={8}>
          <Typography
            sx={{
              fontSize: { xs: '30px', md: '50px' },
              fontFamily: 'Corsica LX Bold',
              lineHeight: 1,
            }}
          >
            Promotions
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {/* <Grid item xs={12} sm={4}>
            <Link
              to='/luckydraw/instructions'
              style={{
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              <img src={luckyDrawBanner} width='100%' alt='Promotion 1' />
              <Typography variant='h4'>Period: 1/5/2024 - TBC</Typography>
            </Link>
          </Grid> */}
          <Grid item xs={12} sm={4}>
            <Link
              to='/campaign'
              style={{
                textDecoration: 'none',
                color: 'inherit',
              }}
              component='div'
            >
              <img src={tngBanner} width='100%' alt='Promotion 2' />
              <Typography variant='h4'>Starting from 3rd February 2025</Typography>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default Promotions
