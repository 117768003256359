import React, { useState } from 'react'
import { Card, Typography, Button, Box, Grid } from '@mui/material'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { usePostSMS } from '../../api/application'
import { declined } from '../../constant/status'
import { DisplayImageDialog } from '../../shared/DisplayImageDialog'

const InfoCard = ({ data }) => {
  const { mutateAsync, isLoading } = usePostSMS()
  const { enqueueSnackbar } = useSnackbar()

  const [openInvoice, setOpenInvoice] = useState(false)
  const handleCloseInvoice = () => {
    setOpenInvoice(false)
  }
  const handleDialogInvoice = () => {
    setOpenInvoice(!openInvoice)
  }
  const uploadPathInvoice = data?.batteryInvoice?.uploadPath

  const [openPhoto, setOpenPhoto] = useState(false)
  const handleClosePhoto = () => {
    setOpenPhoto(false)
  }
  const handleDialogPhoto = () => {
    setOpenPhoto(!openPhoto)
  }
  const uploadPathPhoto = data?.batteryPhoto?.uploadPath

  const onSubmit = async () => {
    const res = await mutateAsync({
      _id: data._id,
    }).catch((err) => {
      enqueueSnackbar(err?.response?.data?.message ?? 'Resend fail', {
        variant: 'error',
      })
    })
    if (res?.status === 200) {
      enqueueSnackbar(`Resent successfully`, {
        variant: 'success',
      })
    }
  }

  return (
    <Box my={3}>
      <Card variant='outlined'>
        <Grid container m={2} spacing={3}>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant='h4'>Name</Typography>
            <Typography variant='h6'>{data?.name}</Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant='h4'>Phone</Typography>
            <Typography variant='h6'>{data?.phone}</Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant='h4'>Email</Typography>
            <Typography variant='h6'>{data?.email}</Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant='h4'>Car Brand</Typography>
            <Typography variant='h6'>{data?.carBrand}</Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant='h4'>Car Model</Typography>
            <Typography variant='h6'>{data?.carModel}</Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant='h4'>State</Typography>
            <Typography variant='h6'>{data?.state}</Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant='h4'>Workshop</Typography>
            <Typography variant='h6'>{data?.workshop}</Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant='h4'>Brand</Typography>
            <Typography variant='h6'>{data?.brand}</Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant='h4'>Status</Typography>
            <Typography variant='h6'>
              {data?.isCompleted ? `${data?.status} (Completed)` : data?.status}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant='h4'>Serial Number</Typography>
            <Typography variant='h6'>{data?.serialNumber}</Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant='h4'>Created At</Typography>
            <Typography variant='h6'>
              {moment(data?.createdAt).format('YYYY-MM-DD h:mm a')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant='h4'>Last Update</Typography>
            <Typography variant='h6'>
              {moment(data?.updatedAt).format('YYYY-MM-DD h:mm a')}
            </Typography>
          </Grid>
          {data?.status === declined && (
            <Grid item xs={12} sm={4} md={4}>
              <Typography variant='h4'>Message: </Typography>
              <Typography variant='h7'>{data?.msg}</Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={4} md={4} alignItems='center'>
            <Button onClick={handleDialogInvoice} size='small' variant='contained'>
              show invoice image
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Button onClick={handleDialogPhoto} size='small' variant='contained'>
              show battery image
            </Button>
          </Grid>
          {data?.isCompleted && (
            <Grid item xs={12} sm={12} md={12}>
              <Button onClick={onSubmit} size='small' variant='contained' disabled={isLoading}>
                RESEND SMS
              </Button>
            </Grid>
          )}
        </Grid>
      </Card>
      <DisplayImageDialog
        handleClose={handleCloseInvoice}
        open={openInvoice}
        uploadPath={uploadPathInvoice}
      />
      <DisplayImageDialog
        handleClose={handleClosePhoto}
        open={openPhoto}
        uploadPath={uploadPathPhoto}
      />
    </Box>
  )
}

export default InfoCard
