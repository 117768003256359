import React, { useState } from 'react'
import { AppBar, Toolbar, Box, Button, Menu, ListItemIcon, MenuItem, Divider } from '@mui/material'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import LogoutIcon from '@mui/icons-material/Logout'
import { useLogout } from '../api/user'

const StyledButton = styled(Button)`
  && {
    color: #000;
    :hover {
      color: #f3b862;
    }
  }
`

const Navbar = ({ user }) => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)
  const openAdminMenu = Boolean(anchorEl)
  const { mutateAsync } = useLogout()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    await mutateAsync()
    return user?.role === 'admin' ? navigate('/admin/login') : navigate('/luckydraw/sign-in')
  }

  const renderAdminMenu = (
    <Menu
      anchorEl={anchorEl}
      open={openAdminMenu}
      onClose={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {[
        // { label: 'Lucky Draw', to: '/admin/luckydraw' },
        { label: 'TNG Campaign', to: '/admin/panel' },
      ].flatMap((item, index, arr) => [
        <MenuItem key={item.to} component={Link} to={item.to}>
          {item.label}
        </MenuItem>,
        index < arr.length - 1 && <Divider key={`divider-${index}`} />,
      ])}

      <Divider />
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  )

  return (
    <AppBar sx={{ background: 'white' }}>
      <Toolbar>
        <Box display='flex' justifyContent='space-between' width='100%'>
          <Box display='flex' align='center'>
            <Link
              to={user ? (user?.role === 'admin' ? '/admin' : '/') : '/'}
              style={{ textDecoration: 'none', color: '#000' }}
            >
              <Box pr={1}>
                <img src='/assets/HTEcoBatteryLogo.png' height={40} />
              </Box>
            </Link>
          </Box>
          {user && (
            <StyledButton
              onClick={handleClick}
              style={{
                textDecoration: 'underline',
              }}
            >
              {user?.name}
            </StyledButton>
          )}
        </Box>
        {renderAdminMenu}
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
