import React from 'react'
import { Navigate } from 'react-router-dom'
import { useMe } from '../api/user'
import { Outlet } from 'react-router-dom'
import { Container } from '@mui/material'
import Navbar from '../shared/Navbar'
import { Box } from '@mui/material'
const AdminPrivateRoute = () => {
  const { data, isLoading } = useMe({})

  if (isLoading) return <></>

  if (!data) return <Navigate to='/admin/login' />

  if (data.role !== 'admin') return <Navigate to='/luckydraw/ticket' />

  return (
    <Container component='main'>
      <Navbar user={data} />
      <Box marginTop={15}>
        <Outlet />
      </Box>
    </Container>
  )
}

export default AdminPrivateRoute
