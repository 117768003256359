import { Typography, Box, Divider, Link, IconButton } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'

export default function Footer() {
  const socialLinks = [
    {
      href: 'https://www.facebook.com/HTEcoBattery',
      icon: <FacebookIcon fontSize='large' style={{ fill: 'white' }} />,
    },
    {
      href: 'https://www.instagram.com/htecobattery/',
      icon: <InstagramIcon fontSize='large' style={{ fill: 'white' }} />,
    },
  ]

  const address = [
    {
      address: 'Johor Bahru (HQ): No 90, Jalan Tampoi, 81200 Johor Bahru, Johor',
      tel: '+6019-736 1797',
      fax: '+607-335 8600',
    },
    {
      address: 'Puchong: No 11, Jalan TPP 3, Taman Perindustrian Putra, 47130 Puchong, Selangor',
      tel: '+603-8088 8336',
      fax: '+60380668749',
    },
    {
      address:
        'Kuantan: 6A, Jalan Industri Semambu 10/3, Kawasan Perindustrian Semambu, 25350 Kuantan, Pahang',
      tel: '+609-560 1313/ +609-560 6363',
      fax: '',
    },
    {
      address: 'Malacca: Lot 2167, Jalan Abadi 4, Taman Malim Jaya, 75250 Melaka',
      tel: '+606-335 3755 ',
      fax: '+606-334 2160',
    },
  ]

  return (
    <Box
      bgcolor='#222222'
      color='#ffff'
      px={3}
      py={5}
      display='flex'
      justifyContent='space-between'
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems='center'
      textAlign={{ xs: 'center', md: 'left' }}
    >
      <Box flexGrow={1} maxWidth={900}>
        <Box mb={1}>
          <Typography fontSize='1.25rem' fontFamily={'Gothic Bold'}>
            Contact
          </Typography>
        </Box>
        <Box mb={1}>
          <Divider style={{ background: 'white' }} />
        </Box>
        <Box mb={1}>
          <Typography fontSize='0.875rem' fontFamily={'Gothic Bold'}>
            HT Eco Battery Sdn Bhd
          </Typography>
        </Box>
        {address.map((item, index) => (
          <Box key={index} pt={1} color='#ffff' fontSize='0.875rem'>
            <Typography fontSize='0.875rem'>{item.address}</Typography>
            <Box display='flex' flexDirection={{ xs: 'column', md: 'row' }}>
              <Typography fontSize='0.875rem'>Tel: {item.tel}</Typography>
              <Box marginLeft={{ md: 2 }}>
                {item.fax && <Typography fontSize='0.875rem'>Fax: {item.fax}</Typography>}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <Box pt={1} pl={{ xs: 0, md: 5 }} minWidth='100px'>
        {socialLinks.map(({ href, icon }, index) => (
          <Link href={href} target='_blank' key={index}>
            <IconButton>{icon}</IconButton>
          </Link>
        ))}
      </Box>
    </Box>
  )
}
