import { Routes, Route } from 'react-router-dom'
import Login from './Login'
import AdminRedirectRoute from './AdminRedirectRoute'
import AdminPrivateRoute from './AdminPrivateRoute'
import LuckyDrawAdminPanel from './LuckyDraw/LuckyDrawAdminPanel'
import ApplicationTable from './TouchNGo/ApplicationTable'
import Header from './Header'
import ReviewApplication from './TouchNGo/ReviewApplication'
import IndirectDealerForm from './LuckyDraw/IndirectDealerForm'

const Page = () => {
  return (
    <Routes>
      <Route element={<AdminPrivateRoute />}>
        <Route path='/panel' element={<ApplicationTable />} />
        <Route path='/review-application' element={<ReviewApplication />} />
        {/* <Route path='/luckydraw' element={<LuckyDrawAdminPanel />} />
        <Route path='/luckydraw-create-indirect' element={<IndirectDealerForm />} /> */}
      </Route>
      <Route element={<Header />}>
        <Route path='/login' element={<Login />} />
        <Route path='/*' element={<AdminRedirectRoute />} />
      </Route>
    </Routes>
  )
}

export default Page
