import { useQuery, useMutation } from 'react-query'
import axios from 'axios'

const ENDPOINT = `/api/application`
const QUERY_KEY = 'application'

export const serializeQuery = (endpoint, query) =>
  endpoint +
  '?' +
  Object.entries(query)
    .map((entry) =>
      entry
        .map((value) =>
          Array.isArray(value) ? value.map(encodeURIComponent).join(',') : encodeURIComponent(value)
        )
        .join('=')
    )
    .join('&')

export const usePostApplication = (config = {}) =>
  useMutation((data) => axios.post(`${ENDPOINT}/create-application`, data), config)

export const useGetApplication = (query = {}, config = {}) =>
  useQuery(
    [`${QUERY_KEY}_single`, 'list', query],
    async () => {
      if (!query?.id) {
        return null
      }
      return (await axios.get(serializeQuery(`${ENDPOINT}/application`, query))).data
    },
    config
  )

export const useGetApplications = (query = {}, config = {}) =>
  useQuery(
    [`${QUERY_KEY}_multiple`, 'list', query],
    async () => (await axios.get(serializeQuery(`${ENDPOINT}/applications`, query))).data,
    config
  )

export const useAllowSubmission = (config = {}) =>
  useQuery(
    [QUERY_KEY, 'allow-submission'],
    async () => (await axios.get(`${ENDPOINT}/allow-submission`)).data,
    config
  )

export const usePatchApplication = (config = {}) =>
  useMutation((updatedData) => axios.patch(`${ENDPOINT}/review-application`, updatedData), config)

export const useExportApplications = (config = {}) =>
  useMutation(() => axios.get(`${ENDPOINT}/export`, { responseType: 'blob' }), config)

export const usePostSMS = (config = {}) =>
  useMutation((data) => axios.post(`${ENDPOINT}/resend-sms`, data), config)

export const usePostIndirectDealer = (config = {}) =>
  useMutation((data) => axios.post(`${ENDPOINT}/create-indirect-dealer`, data), config)

export const useGetIndirectDealers = (query = {}, config = {}) =>
  useQuery(
    [`${QUERY_KEY}_indirect`, 'list', query],
    async () => (await axios.get(serializeQuery(`${ENDPOINT}/indirect-dealers`, query))).data,
    config
  )

export const usePatchIndirectDealer = (config = {}) =>
  useMutation((updatedData) => axios.patch(`${ENDPOINT}/indirect-dealer`, updatedData), config)
